import React, { useEffect, useState } from "react";
import { Flex } from "rebass";
import styled, { withTheme } from "styled-components";
import SquareButton from "../../Buttons/SquareButton2";
import GratitudeSuccessMessage from "../SendGratitudeModal/GratitudeSuccessMessage";
import PopupModal from "../../PopupBlurModal";
import PeopleSearchBar from "../../PeopleSearchBar";
import { Input, TextArea } from "../../../../views/Settings/common/Components";
import useForm from "../../../../views/Settings/common/useForm";
import { InputCounter } from "../../../../views/Profile/Profile";
import { Icon } from "../../Icon";
import ReactTooltip from "react-tooltip";
import CreateContactModal from "../../ShareModal/CreateContact";
import Utils from "../../../../../../../libs/Utils";
import useOnChangeRoute from "../../../../../../../libs/useOnRouteChange";

const Title = styled.h1`
    color: ${({ theme }) => theme.text};
    font-size: ${({ theme }) =>
        theme.mobileDevice ? theme.fonts.XS : theme.fonts.L};
    font-weight: ${({ theme }) => theme.fonts.bold};
    text-align: center;
`;

const Label = styled.label`
    font-weight: 700;
    font-size: 16px;
    color: #333333;
    margin-top: ${({ theme }) => theme.spacing[13]};
    margin-bottom: 0.5em;
`;

const Disclaimer = styled.div`
    font-weight: 500;
    font-size: 12px;
    color: #929292;
    flex-basis: 80%;

    @media (max-width: ${({ theme }) => theme.breakpoints[1]}) {
        margin-bottom: 1rem;
    }
`;

const ActionLabel = styled.span`
    color: #1fc07f;
    text-decoration: underline;
    cursor: pointer;
    margin: 0 0.5em;
`;

const CreateGratitude = ({
    t,
    theme,
    user,
    open,
    handleClose,
    addGratitude
}) => {
    const { formValues, reset, update, initialize } = useForm();
    const [createContactModal, setCreateContactModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    useEffect(() => {
        initialize({
            selectedUser: null,
            title: "",
            message: ""
        });
    }, []);

    useOnChangeRoute(() => {
        if (!open) return;
        handleClose();
        reset();
        setShowSuccessMessage(false);
    });

    const onChange = ev => update({ key: ev.name, value: ev.value });

    const handleSendOtherEndorsement = () => {
        reset();
        setShowSuccessMessage(false);
    };

    const onSave = async () => {
        if (!formValues.selectedUser || !formValues.message.length) return;
        setIsLoading(true);
        await addGratitude(
            {
                addressed_user: formValues.selectedUser.uid,
                title: formValues.title,
                content: formValues.message
            },
            formValues.selectedUser
        );
        setIsLoading(false);
        setShowSuccessMessage(true);
    };

    const getFirstName = () => {
        const { first_name, name } = formValues.selectedUser;
        const firstName = first_name || name;
        // Add s to the end of the name if it ends with s
        return Utils.getNameWithAnSAtTheEnd(firstName);
    };

    const submitDisabled =
        !formValues.selectedUser ||
        !formValues.title.length ||
        !formValues.message.length;

    const renderForm = () => (
        <Flex
            flexDirection={"column"}
            alignItems="center"
            pt={[
                theme.spacing[3],
                theme.spacing[3],
                theme.spacing[10],
                theme.spacing[10]
            ]}
            pb={[
                theme.spacing[6],
                theme.spacing[6],
                theme.spacing[24],
                theme.spacing[24]
            ]}
            px={[
                theme.spacing[6],
                theme.spacing[6],
                theme.spacing[24],
                theme.spacing[24]
            ]}
        >
            <Title>{t("title")}</Title>
            <Flex width={[1]} flexDirection="column" alignItems={"flex-start"}>
                <Flex alignItems={"center"}>
                    <Label>{t("label")}</Label>
                    <Flex
                        ml={theme.spacing[2]}
                        mb="0.5rem"
                        style={{ cursor: "pointer" }}
                        alignItems="center"
                    >
                        <Icon
                            icon="info"
                            color={theme.newBlue}
                            size="md"
                            data-tip
                            data-for={"endorsement-info"}
                        />
                        {open && (
                            <ReactTooltip
                                id={"endorsement-info"}
                                delayHide={200}
                                place="bottom"
                                type="light"
                                effect="solid"
                                className="form-tooltip"
                                clickable
                            >
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: t("tooltip")
                                    }}
                                />
                            </ReactTooltip>
                        )}
                    </Flex>
                </Flex>
                <PeopleSearchBar
                    clearCondition={open}
                    onClickSuggestion={user =>
                        onChange({ name: "selectedUser", value: user })
                    }
                    onClearSuggestion={() =>
                        onChange({ name: "selectedUser", value: null })
                    }
                />
            </Flex>
            <Flex width={[1]} flexDirection="column" mt={[theme.spacing[5]]}>
                <Input
                    type="text"
                    name="title"
                    value={formValues.title}
                    onChange={({ target }) => onChange(target)}
                    placeholder={t("title_placeholder")}
                />
            </Flex>
            <Flex width={[1]} flexDirection="column" mt={[theme.spacing[5]]}>
                <TextArea
                    type="text"
                    name="message"
                    value={formValues.message}
                    onChange={({ target }) => onChange(target)}
                    maxLength="5000"
                    rows={6}
                    style={{ resize: "vertical", height: "100px" }}
                    placeholder={t("message_placeholder")}
                />
                <Flex
                    mt={theme.spacing[2]}
                    justifyContent={"flex-end"}
                    style={{ minHeight: "24px" }}
                >
                    {!!formValues.message && !!formValues.message.length && (
                        <InputCounter>
                            {t("characters_left", {
                                charactersLeft: 5000 - formValues.message.length
                            })}
                        </InputCounter>
                    )}
                </Flex>
            </Flex>
            <Flex
                width={[1]}
                mt={[theme.spacing[6]]}
                flexDirection={["column", "column", "row", "row"]}
                justifyContent={"space-between"}
                alignItems={["flex-end", "flex-end", "center", "center"]}
            >
                <Disclaimer>
                    {t("disclaimer_label")}
                    <ActionLabel onClick={() => setCreateContactModal(true)}>
                        {t("disclaimer_action_label")}
                    </ActionLabel>
                    {t("disclaimer_action_label_2")}
                </Disclaimer>
                <SquareButton
                    disabled={submitDisabled}
                    onClick={onSave}
                    bg={theme.shamRockGreen}
                    isLoading={isLoading}
                    fontSize="XS"
                    text={t("button")}
                />
            </Flex>
        </Flex>
    );

    return (
        <>
            <PopupModal
                open={open && !createContactModal}
                handleClose={handleClose}
                width={!showSuccessMessage && [0.9, 0.9, 0.7, 0.7]}
            >
                {showSuccessMessage ? (
                    <GratitudeSuccessMessage
                        fullName={getFirstName()}
                        buttonLabel={t(
                            "send_another_endorsement"
                        )}
                        userName={formValues.selectedUser && formValues.selectedUser.userName}
                        onClick={handleSendOtherEndorsement}
                    />
                ) : (
                    renderForm()
                )}
            </PopupModal>
            <CreateContactModal
                open={createContactModal}
                onClose={() => setCreateContactModal(false)}
            />
        </>
    );
};

export default withTheme(CreateGratitude);
