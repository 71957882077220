import styled, { css } from 'styled-components';
import { Box, Flex } from 'rebass';
import LinkComponent from '../../atoms/Link';
import { theme } from '../../../../../theme';
import { scrollBarStyles } from '../../atoms/shared/ScrollBar';
import { CLIENT_REVIEW_OPTIONS } from '../../../../../assets/strings';


export const GoBackContainer = styled(Flex)`
    align-items: center;
    cursor: pointer;
    width: fit-content;
    padding: 0.5rem 0.5rem 0.5rem 0;

    svg {
        transform: rotate(90deg);
        transition: transform 0.2s ease-in-out;
    }

    :hover {
        svg {
            transform: rotate(90deg) translateY(2px);
            transition: transform 0.2s ease-in-out;
        }
    }
`;

const handleHeaderBackgroundColorByPlan = ({ theme, isPro, isEssentialPlus}) => {
    if (isPro) {
        return theme.greenPro
    }
    if (isEssentialPlus) {
        return theme.coolBlack
    }
    return theme.timberwolf
}
const handleBackgroundColorByPlan = ({ theme, isPro, isEssentialPlus}) => {
    if (isPro) {
        return theme.mountainMeadow
    }
    if (isEssentialPlus) {
        return theme.darkBlue
    }
    return theme.timberwolf
}

export const HeaderByPlan = styled.div`
    position: absolute;
    left: 0;

    width: 100%;
    height: ${({ theme }) => theme.mobileDevice ? "24px" : "40px"};
    background-color: ${handleHeaderBackgroundColorByPlan};
`

export const ProfileContainer = styled(Box)`
    width: 100%;
    max-width: 1440px;
`

export const BadgeByPlan = styled.div`
    margin: 0 24px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${({ theme }) => theme.fonts.XXS};
    line-height: ${({ theme }) => theme.lineHeights.XXS};
    font-weight: 700;
    padding: ${({ theme }) => `${theme.spacing[0]} ${theme.spacing[4]}`};
    color: ${({ theme, isPro, isEssentialPlus }) => isPro || isEssentialPlus ? theme.white : theme.text};
    background-color: ${handleBackgroundColorByPlan};
    height: 32px;
`

export const Card = styled(Box)`
    margin-bottom: 24px;
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.darkGray};
`

export const MainSection = styled(Box)`
    ${({ theme }) => !theme.mobileDevice && css`
        padding-top: 32px;
        padding-bottom: 32px;
        padding-left: 24px;
        padding-right: 40px;`
    }
    ${({ theme, isOpActivity }) => theme.mobileDevice && isOpActivity && css`
        padding: ${theme.spacing[4]};`
    }
    ${({ theme, isOpActivity }) => theme.mobileDevice && !isOpActivity && css`
        padding: ${theme.spacing[4]} 0;`
    }
    border-top: 1px solid ${({ theme }) => theme.timberwolf};
`

export const CardTitle = styled.h1`
    color: ${({ theme }) => theme.coolBlack};
    font-size: ${({ theme }) => theme.mobileDevice ? theme.fonts.XS : theme.fonts.L};
    line-height: ${({ theme }) => theme.mobileDevice ? theme.lineHeights.XS : theme.lineHeights.M};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    margin-top: 0;
    margin-bottom: 0;
`

export const CardSubtitle = styled.h2`
    color: ${({ theme }) => theme.coolBlack};
    font-size: ${({ theme }) => theme.fonts.S};
    line-height: ${({ theme }) => theme.lineHeights.S};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    margin-top: 0;
    margin-bottom: ${({ theme, noMargin }) => noMargin ? 0 : theme.spacing[4]}
`

export const CardDescription = styled.p`
    color: ${({ theme }) => theme.taupeGray};
    font-size: ${({ theme }) => theme.mobileDevice ? theme.fonts.XXS : theme.fonts.XS};
    line-height: ${({ theme }) => theme.mobileDevice ? theme.lineHeights.XS : theme.lineHeights.XS};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    margin-top: 0;
    margin-bottom: ${({ theme, noMargin }) => noMargin ? 0 : theme.spacing[4]}
`

export const SideCardTitle = styled.h2`
    color: ${({ theme }) => theme.coolBlack};
    font-size: ${({ theme }) => theme.mobileDevice ? theme.fonts.XS : theme.fonts.S};
    line-height: ${({ theme }) => theme.mobileDevice ? theme.lineHeights.XS : theme.lineHeights.S};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    margin-top: 0;
    margin-bottom: ${({ theme, noMargin }) => noMargin ? 0 : theme.spacing[4]}
`

export const SideCardSubtitle = styled.h3`
    color: ${({ theme }) => theme.coolBlack};
    font-size: ${({ theme }) => theme.mobileDevice ? theme.fonts.XXS : theme.fonts.XS};
    line-height: ${({ theme }) => theme.lineHeights.XS};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    margin-top: 0;
    margin-bottom: ${({ theme, noMargin }) => noMargin ? 0 : theme.spacing[4]}
`

export const InputContainer = styled(Flex)`
    flex-direction: column;
    margin-bottom: ${({ theme }) => theme.mobileDevice && theme.spacing[3]};
`

export const InputLabel = styled.label`
    color: ${({ theme }) => theme.text};
    font-size: ${({ theme }) => theme.fonts.XS};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    line-height: ${({ theme }) => theme.lineHeights.XS};
    margin-bottom: ${({ theme }) => theme.spacing[2]};
    display: ${({ theme }) => theme.mobileDevice && "none"};
`

export const InputCounter = styled.p`
    color: ${({ theme }) => theme.taupeGray};
    font-size: ${({ theme }) => theme.fonts.XXS};
    line-height: ${({ theme }) => theme.lineHeights.XXS};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    margin-top: 0;
    margin-bottom: ${({ theme, noMargin }) =>
        noMargin ? 0 : theme.spacing[4]};
`;

export const ItemTItle = styled.h3`
    color: ${({ theme }) => theme.coolBlack};
    font-size: ${({ theme }) => theme.mobileDevice ? theme.fonts.XXS : theme.fonts.S};
    line-height: ${({ theme }) => theme.mobileDevice ? theme.lineHeights.XS : theme.lineHeights.S};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    text-overflow: ellipsis;
    overflow-x: hidden;
    white-space: nowrap;
    margin: 0;
    max-width: ${({ theme }) => theme.mobileDevice && "90%"}};
`

export const ItemSubtitle = styled.h4`
    color: ${({ theme }) => theme.coolBlack};
    font-size: ${({ theme }) => theme.mobileDevice ? theme.fonts.XXXS : theme.fonts.XS};
    line-height: ${({ theme }) => theme.mobileDevice ? theme.lineHeights.XS : theme.lineHeights.XS};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    margin: 0;
`

export const ItemDescription = styled.p`
    color: ${({ theme }) => theme.taupeGray};
    font-size: ${({ theme }) => theme.mobileDevice ? theme.fonts.XXXS : theme.fonts.XS};
    line-height: ${({ theme }) => theme.mobileDevice ? theme.lineHeights.XS : theme.lineHeights.XS};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    margin: 0;
`

export const FeedbackItemsUserName = styled.h4`
    color: ${({ theme }) => theme.coolBlack};
    font-size: ${({ theme }) => theme.fonts.XXS};
    line-height: ${({ theme }) => theme.lineHeights.XS};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    margin-top: 0;
    margin-bottom: 0;
    transition: color 0.3s ease-in-out;

    a {
        color: ${({ theme }) => theme.newBlue};
        font-size: ${({ theme }) => theme.fonts.XXS};
        line-height: ${({ theme }) => theme.lineHeights.XS};
        font-weight: ${({ theme }) => theme.fontWeights.medium};
        text-decoration: underline;
        cursor: pointer;
    }

    ${({ theme }) => !theme.isEndorsements && css`
        :hover {
            transition: color 0.3s ease-in-out;
            color: ${theme.shamRockGreen};
            cursor: pointer;
        }
    `}
`;

export const FeedbackItemsUserProfession = styled.p`
    color: ${({ theme }) => theme.taupeGray};
    font-size: ${({ theme }) => theme.fonts.XXXS};
    line-height: ${({ theme }) => theme.lineHeights.XXS};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    margin: 0;
`;

export const FeedbackItemsClientRating = styled(FeedbackItemsUserProfession)`
    color: ${({ theme }) => theme.text};

    + p {
        margin-left: ${({ theme }) => theme.spacing[2]};
    }
`;

const handleClientRatingColor = ({ theme, clientRating }) => {
    const clientRatingColors = {
        [CLIENT_REVIEW_OPTIONS.POSITIVE]: theme.shamRockGreen,
        [CLIENT_REVIEW_OPTIONS.NEUTRAL]: theme.taupeGray,
        [CLIENT_REVIEW_OPTIONS.NEGATIVE]: theme.lightCarminePink,
    }
    return clientRatingColors[clientRating]
}

export const FeedbackItemsClientRatingValue = styled(FeedbackItemsUserProfession)`
    color: ${handleClientRatingColor};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

export const FeedbackItemsMessage = styled.p`
    color: ${({ theme }) => theme.taupeGray};
    font-size: ${({ theme }) => theme.fonts.XS};
    line-height: ${({ theme }) => theme.lineHeights.XS};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    margin-top: ${({ theme }) => theme.spacing[4]};
    margin-bottom: ${({ theme }) => theme.spacing[1]};
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    opacity: ${({ hidden }) => hidden && 0.6};
`;

export const FeedbackItemsHiddenMessage = styled(Flex)`
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgb(255, 255, 255, 0.7);
    z-index: 2;
    justify-content: center;
    align-items: center;
`

export const FeedbackItemsHeaderContainer = styled(Flex)`
    position: relative;
    opacity: ${({ hidden }) => hidden && 0.6};
`

export const Link = styled(LinkComponent)`
    display: flex;
    align-items: center;
    font-size: ${({ theme }) => theme.fonts.XS};
    line-height: ${({ theme }) => theme.lineHeights.XS};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    color: ${({ theme }) => theme.newBlue};

    &:after {
        background-color: ${({ theme }) => theme.newBlue};
    }

    svg {
        margin-left: ${({ theme }) => theme.spacing[1]};
        transition: transform 0.3s ease-in-out;
        transform: translateX(0);
    }

    :hover {
        svg {
            transition: transform 0.3s ease-in-out;
            transform: translateX(2px);
        }
    }
`


export const HeaderItemContainer = styled(Flex)`
    position: relative;
`


export const DragIconContainer = styled(Flex)`
    position: absolute;
    padding-left: 0.4rem;
    left: -35px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    opacity: 0;

    padding: 0.5rem;
    cursor: pointer;
    background: transparent;
    transition: all 0.3s ease;
    :hover, :active {
        background: ${({ theme }) => theme.greyLight2};
        opacity: 1;
        transition: all 0.3s ease;
    }

    @media (max-width: ${({ theme }) => theme.mediaBreakpoints.tablet}px) {
        display: none;
    }
`;

export const SortableContainer = styled(Box)`
    margin-bottom: ${({ isOverlay }) => isOverlay ? "8px" : "20px"};
    background: ${({ theme }) => theme.clearGray};
    width: ${({ isOverlay }) => isOverlay ? "228px" : "285px"};

    ${HeaderItemContainer} {
        width: ${({ isOverlay }) => isOverlay && "100%"};
    }

    :hover {
        ${DragIconContainer} {
            transition: all 0.3s ease;
            opacity: 1;
        }
    }

    @media (max-width: ${({ theme }) => theme.mediaBreakpoints.tablet}px) {
        width: 100%;
    }
`;

export const ScrollableContainer = styled(Flex)`
    overflow-y: auto;
    max-height: 400px;
    margin-top: 30px;
    ${scrollBarStyles}
`;

export const selectStyle = {
    control: styles => ({
        ...styles,
        border: "1px solid #929292",
        height: "50px",
        fontSize: "16px",
        borderRadius: "4px",
        caretColor: "transparent"
    }),
    option: (styles, { isFocused, isSelected }) => ({
        ...styles,
        backgroundColor: isSelected
            ? theme.shamRockGreen
            : isFocused
            ? theme.greenMedium
            : "transparent",
        color: isSelected || isFocused ? "white" : "black",
        fontWeight: isSelected ? "bold" : "normal"
    })
};