import React from "react";
import { Flex, Box } from "rebass";
import styled, { withTheme } from "styled-components";
import { Icon } from "../Icon";
import { ContactAvatar } from "../../../atoms/shared/ContactAvatar";
import { theme } from "../../../../../../theme";
import { useMediaQuery } from "../../../../../context/useMediaQueries";


const Wrapper = styled(Flex)`
    height: auto;
    background: ${(props) => props.background};
    cursor: pointer;
   
    padding: 8px;
`;

const UserInfoSection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap:6
    //gap: 16px;
    cursor: pointer;
    & + & {
        margin-left: 1em;
    }
`;

const Label = styled.div`
   // font-size: 14px;
    font-weight: 500;
    padding-left: 0.5em;
    cursor: pointer;
    font-size: ${({ theme }) => theme.mobileDevice ? '12.8px' : '14px'};
`;

const MainLabel = styled(Label)`
    font-weight: 700;
    padding: 0;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const MailLabel = styled(Label)`
    font-weight: 500;
    padding: 0;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #000;
`;

const PhoneLabel = styled(Label)`
    font-weight: 500;
    padding: 0;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #000;
`;

const Avatar = styled.img`
    height: 27px;
    width: 27px;
    border-radius: 50%;
    object-fit: cover;
    cursor: pointer;
`;

const ProMemberText = styled(Label)`
    color: #00A15F;
    cursor: pointer;
`;

const EssentialPlusText = styled(Label)`
    color: #1D55B6;
    cursor: pointer;
`;

const EssentialText = styled(Label)`
    color: #929292;
    cursor: pointer;
    padding: 0;
`;

const SuscriptionLevels = {
    PRO: 'PRO',
    ESSENTIALPLUS: 'ESSENTIAL+',
    ESSENTIAL: 'ESSENTIAL',
};

const CompanyContainer = styled.div`
    display: ${({ theme }) => theme.mobileDevice ? 'none' : 'flex'};
    margin-left: 12px;
    justify-content: center;
    align-items: center;
`;

const UnableMessage = styled.div`

`;


const EssentialPlusCheck = () => <Icon icon="check-filled" width={20} height={20} color="#1D55B6" />;

const ProMemberCheck = () => <Icon icon="check-filled" width={20} height={20} color="#00A15F" />;

const ProMember = () => (
    <UserInfoSection>
        <ProMemberCheck />
        <ProMemberText>
            Pro Member
        </ProMemberText>
    </UserInfoSection>
);

const EssentialPlus = () => (
    <UserInfoSection>
        <EssentialPlusCheck />
        <EssentialPlusText>
            Essential+
        </EssentialPlusText>
    </UserInfoSection>
);

const Essential = () => (
    <UserInfoSection>
        <EssentialText>
            Essential
        </EssentialText>
    </UserInfoSection>
);

const UserInfo = ({ 
    id, 
    avatarUrl, 
    name, 
    organization, 
    location, 
    tier, 
    background = '#FFFFFF', onClick = () => { }, 
    theme, 
    isSearchSuggestion, 
    firstName, 
    lastName, 
    phone, 
    email ,
    privatePhone,
    privateEmail,
    office_phone,
    mobile_phone,
    setInputIsActive,
    setHiddenCreateContact
}) => {
    const full_name = name ? name : `${firstName} ${lastName}`;
    let isMobile = useMediaQuery('(max-width: 480px)')
    let isDekstop = useMediaQuery('(min-width: 481px)')

    return (
        <Wrapper
            justifyContent={isSearchSuggestion && ["center", "center", "flex-start", "flex-start"]}
            alignItems={isSearchSuggestion && ["flex-start", "flex-start", "center", "center"]}
            flexDirection={isSearchSuggestion && ["column", "column", "row", "row"]}
            background={background}
            py={isSearchSuggestion && [theme.spacing[2], theme.spacing[2], 0, 0]}
            px={[theme.spacing[2], theme.spacing[2], theme.spacing[5], theme.spacing[5]]}
            onClick={() =>{ 
                onClick(id);
                setInputIsActive(false);
                setHiddenCreateContact(false);
            }}
          
            
        >
            <Flex mb={isSearchSuggestion && [theme.spacing[1], theme.spacing[1], 0, 0]}  >
                <UserInfoSection style={{ marginRight: 10 }}>
                    {
                        avatarUrl && <Avatar src={avatarUrl} />
                    }
                    {
                        !avatarUrl && firstName && <ContactAvatar name={firstName} lastName={lastName} size="27px" fontSize="14px" />
                    }

                </UserInfoSection>
                <Flex flexDirection={'column'} >
                    <UserInfoSection>
                        <MainLabel>
                            {full_name}
                        </MainLabel>
                        {organization && (
                            <CompanyContainer>
                                <Icon icon="building" size="md" color="#057AFF" />
                                <Label>
                                    {organization}
                                </Label>
                            </CompanyContainer>)}
                       {location  &&
                           <Flex ml={(!isSearchSuggestion || !theme.mobileDevice) ? [theme.spacing[2]] : '38px'}  >
                           {
                               location &&
                               <UserInfoSection>
                                   <Icon icon="location" size="md" color="#057AFF" />
                                   <Label>
                                       {location}
                                   </Label>
                               </UserInfoSection>
                           }
                           {!theme.mobileDevice && tier === SuscriptionLevels.PRO && <ProMember />}
                           {!theme.mobileDevice && tier === SuscriptionLevels.ESSENTIALPLUS && <EssentialPlus />}
                           {!theme.mobileDevice && tier === SuscriptionLevels.ESSENTIAL && <Essential />}
                          </Flex> 
                       }
                    </UserInfoSection>
                    {privatePhone && privateEmail ?
                         <MailLabel> 
                             Contact info is unable to be shared due to this Member's settings. 
                         </MailLabel>

                       :

                       <Flex   flexDirection={'row'} alignItems={'center'} style={{ gap: 4}}>
                        {(email && privateEmail !== true ) &&
                            <Flex flexDirection={'row'} style={{ gap: 4}} alignItems={'center'}>
                                <Icon icon="mail" size="md" color="#057AFF" />
                                <MailLabel>
                                    {email}
                                </MailLabel>
                            </Flex>

                        }

                        {(phone && privatePhone !== true )&&

                            <Flex flexDirection={'row'}  style={{ gap: 4, marginTop: 6 }} alignItems={'center'}>
                                <Icon icon="phone" size="md" color="#057AFF" />
                                <PhoneLabel>
                                    {phone}
                                </PhoneLabel>
                            </Flex>

                        }
                        {mobile_phone && 
                            <Flex flexDirection={'row'}  style={{ gap: 4}} alignItems={'center'}>
                                <Icon icon="phone" size="md" color="#057AFF" />
                                <PhoneLabel>
                                    {mobile_phone}
                                </PhoneLabel>
                            </Flex>
                        }
                        {office_phone && 
                                <Flex flexDirection={'row'}  style={{ gap: 4}} alignItems={'center'}>
                                <Icon icon="phone" size="md" color="#057AFF" />
                                <PhoneLabel>
                                    {office_phone}
                                </PhoneLabel>
                            </Flex>
                        }
                    </Flex>

                    }
                </Flex>

            </Flex>


        </Wrapper>
    );
};

export default withTheme(UserInfo);