import React, { useEffect, useMemo, useState } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Box, Flex } from "rebass";
import { withTheme } from "styled-components";
import SquareButton from "../Buttons/SquareButton2";
import { Text, Title } from "../Text";
import { Formik } from "formik";
import * as yup from "yup";
import Field from "../Inputs/SquareWhiteInput/SquareWhiteInput";
import CollapsableSection from "../CollapsableSection";
import { FormWithConsumer } from "../../../../../hocs/Form2";
import PopupModal from "../PopupBlurModal";
import {
    MobileWrapper,
    ContactFormTitle,
    ContactFormTitleContainer,
    FormContainer,
    InputLabel,
    HelpText
} from "./CreateContactModal";
import { Icon } from "../Icon";
import MultiSelect from "../Inputs/SquareWhiteInputMultiSelect/MultiSelect";
import { theme as baseTheme } from "../../../../../../theme";
import DateInput from "../Inputs/Date";
import PhoneNumberInput from "../../../views/Settings/common/PhoneInput";

const withProps = Component => withTranslation(["myNetwork"])(withTheme(FormWithConsumer(Component)));

const ErrorPopup = withProps(props => {

    const { open, message, hide, t, theme } = props;

    return (
        <PopupModal modalStyles={{ zIndex: 99999999 }} open={open} handleClose={hide}>
            <Flex flexDirection="column" alignItems="center" p={[theme.spacing[6]]}>
                <Title>
                    {t("duplicateContateMessage")}
                </Title>
                <Box my="16px">
                    <Text>
                        { message }
                    </Text>
                </Box>
                <SquareButton small text="OK" onClick={hide} bg={theme.shamRockGreen} />
            </Flex>
        </PopupModal>
    );
});

const errorStyles = { color: baseTheme.redError };
const titleStyles = {
    fontSize: "16px",
    fontWeight: 700,
    letterSpacing: "-0.36px",
    lineHeight: "36px",
    color: baseTheme.coolBlack,
    marginRight: "12px"
};
const inputStyles = { color: "black" };
const labelStyles = { fontWeight: "400", color: "black" };
const formContainerStyles = { maxHeight: 600 };

const formSchema = yup.object().shape(
    {
        first_name: yup.string().required(),
        last_name: yup.string().required(),
        email: yup
            .string()
            .email()
            .when("mobile_phone", {
                is: mobile_phone => !mobile_phone || mobile_phone.length === 0,
                then: yup
                    .string()
                    .email()
                    .required(),
                otherwise: yup
                    .string()
                    .email()
                    .nullable()
            }),
        mobile_phone: yup.string().when("email", {
            is: email => !email || email.length === 0,
            // longer than 11 digits and include +
            then: yup
                .string()
                .required()
                .matches(/^[+][0-9]{11,}$/, "Invalid phone number"),
            otherwise: yup.string().nullable()
        })
    },
    ["email", "mobile_phone"]
);

const FormFields = withProps(({
    t,
    theme,
    subtitle,
    filtersCollapsed,
    contactData,
    createContact,
    updateContact,
    states,
    setFiltersCollapsed,
    onClose,
    professionOptions,
    onContactCreated = () => { },
}) => {
    const [isLoading, setIsLoading] = useState(false);

    const onToggleCollapse = (name, collapsed) => {
        const newCollapseds = { ...filtersCollapsed };
        newCollapseds[name] = collapsed;
        setFiltersCollapsed(newCollapseds);
    };

    const isEdit = useMemo(() => {
        return contactData.first_name ? true : false;
    }, [contactData]);

    const onSubmit = async (values) => {
        setIsLoading(true);
        const contact = isEdit ? await updateContact(values) : await createContact(values);
        setIsLoading(false);
        // If contact is null, it means there was an error
        if (!contact) return;
        // If contact is not null, it means it was created/updated successfully
        if (!isEdit) onContactCreated(contact);
        onClose();
    }

    return (
        <Formik
            onSubmit={onSubmit}
            validationSchema={formSchema}
            initialValues={contactData}
            enableReinitialize
        >
            {({
                values,
                errors,
                handleChange,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                isValid
            }) => {
                return (
                    <React.Fragment>
                        <ContactFormTitleContainer>
                            <ContactFormTitle>
                                {theme.mobileDevice && (
                                    <Icon
                                        icon="chevron-left"
                                        width={14}
                                        height={14}
                                        color={theme.coolBlack}
                                        style={{
                                            marginRight: 16,
                                            marginTop: "2px"
                                        }}
                                        onClick={onClose}
                                    />
                                )}
                                {isEdit ? t("editContact") : t("createContact")}
                            </ContactFormTitle>
                            {subtitle && <HelpText>{subtitle}</HelpText>}
                        </ContactFormTitleContainer>
                        {/* FORM FIELDS */}
                        <Box px={[20, 20, 88, 88]} style={formContainerStyles}>
                            {/* //BASIC INFO SECTION */}
                            <CollapsableSection
                                title={t("basicInfo")}
                                onClickCollapse={collapsed =>
                                    onToggleCollapse("basicInfo", collapsed)
                                }
                                collapsed={filtersCollapsed["basicInfo"]}
                                rootStyles={{ marginTop: "1em" }}
                                titleStyles={titleStyles}
                                subtitleStyles={{ color: theme.onBackground }}
                                arrowDimensions={{ height: 9, width: 15 }}
                                arrowColor={theme.coolBlack}
                                flexJustify="start"
                            >
                                {/* <SubTitle style={subtitleStyles}>{t("basicInfo")}</SubTitle> */}
                                <Flex flexWrap={"wrap"}>
                                    <Box
                                        width={[1, 1, 0.5, 0.5]}
                                        mt={3}
                                        pr={[0, 0, 12, 12]}
                                    >
                                        <Field
                                            inputStyles={inputStyles}
                                            labelStyles={labelStyles}
                                            errorStyles={errorStyles}
                                            name="first_name"
                                            defaultValue={values.first_name}
                                            onChange={handleChange}
                                            error={!!errors.first_name}
                                            label={t("firstName")}
                                        />
                                    </Box>
                                    <Box
                                        width={[1, 1, 0.5, 0.5]}
                                        mt={3}
                                        pl={[0, 0, 12, 12]}
                                    >
                                        <Field
                                            defaultValue={values.last_name}
                                            onChange={handleChange}
                                            inputStyles={inputStyles}
                                            labelStyles={labelStyles}
                                            errorStyles={errorStyles}
                                            error={!!errors.last_name}
                                            name="last_name"
                                            label={t("lastName")}
                                        />
                                    </Box>
                                    <Box
                                        width={[1, 1, 0.5, 0.5]}
                                        mt={3}
                                        pr={[0, 0, 12, 12]}
                                    >
                                        <InputLabel>
                                            {t("mobile_phone")}
                                        </InputLabel>
                                        <PhoneNumberInput
                                            useCustomStyle={{
                                                marginRight: 0,
                                                border: "1px solid #DDE3E8"
                                            }}
                                            name="mobile_phone"
                                            value={values.mobile_phone}
                                            onChange={ev =>
                                                setFieldValue(ev.name, ev.value)
                                            }
                                        />
                                    </Box>
                                    <Box
                                        width={[1, 1, 0.5, 0.5]}
                                        mt={3}
                                        pl={[0, 0, 12, 12]}
                                    >
                                        <Field
                                            error={!!errors.email}
                                            inputStyles={inputStyles}
                                            labelStyles={labelStyles}
                                            errorStyles={errorStyles}
                                            name="email"
                                            onChange={handleChange}
                                            label={t("email")}
                                            defaultValue={values.email}
                                        />
                                    </Box>
                                    <Box mt={3}>
                                        <Text
                                            fontSize="XXS"
                                            color={theme.taupeGray}
                                        >
                                            {t("basicInfoRequired")}
                                        </Text>
                                    </Box>
                                </Flex>
                            </CollapsableSection>
                            {/* //WORK INFO SECTION */}
                            {filtersCollapsed["basicInfo"] && (
                                <Box
                                    mt={3}
                                    style={{ borderTop: "1px solid #C4C4C4" }}
                                />
                            )}
                            <CollapsableSection
                                flexJustify="start"
                                title={t("workInfo")}
                                onClickCollapse={collapsed =>
                                    onToggleCollapse("workInfo", collapsed)
                                }
                                collapsed={filtersCollapsed["workInfo"]}
                                rootStyles={{ marginTop: "1em" }}
                                titleStyles={titleStyles}
                                subtitleStyles={{ color: theme.onBackground }}
                                arrowDimensions={{ height: 9, width: 15 }}
                                arrowColor={theme.coolBlack}
                            >
                                <Flex flexWrap={"wrap"}>
                                    <Box
                                        width={[1, 1, 0.5, 0.5]}
                                        mt={3}
                                        pr={[0, 0, 12, 12]}
                                    >
                                        <Field
                                            label={t("company")}
                                            inputStyles={inputStyles}
                                            labelStyles={labelStyles}
                                            errorStyles={errorStyles}
                                            name="work_info.company"
                                            defaultValue={
                                                (values.work_info &&
                                                    values.work_info.company) ||
                                                ""
                                            }
                                            onChange={handleChange}
                                            error={!!errors.company}
                                        />
                                    </Box>
                                    <Box
                                        width={[1, 1, 0.5, 0.5]}
                                        mt={3}
                                        pl={[0, 0, 12, 12]}
                                    >
                                        <InputLabel>
                                            {t("officePhone")}
                                        </InputLabel>
                                        <PhoneNumberInput
                                            useCustomStyle={{
                                                marginRight: 0,
                                                border: "1px solid #DDE3E8"
                                            }}
                                            name="work_info.office_phone"
                                            value={
                                                (values.work_info &&
                                                    values.work_info
                                                        .office_phone) ||
                                                ""
                                            }
                                            onChange={ev =>
                                                setFieldValue(ev.name, ev.value)
                                            }
                                        />
                                    </Box>
                                    <Box
                                        mt={3}
                                        width={[1, 1, 0.5, 0.5]}
                                        pr={[0, 0, 12, 12]}
                                    >
                                        <Field
                                            label={t("streetAddress")}
                                            inputStyles={inputStyles}
                                            labelStyles={labelStyles}
                                            errorStyles={errorStyles}
                                            name="work_info.located_at.address"
                                            defaultValue={
                                                (values.work_info &&
                                                    values.work_info
                                                        .located_at &&
                                                    values.work_info.located_at
                                                        .address) ||
                                                ""
                                            }
                                            onChange={handleChange}
                                            error={!!errors.address}
                                        />
                                    </Box>
                                    <Box
                                        width={[1, 1, 0.5, 0.5]}
                                        mt={3}
                                        pl={[0, 0, 12, 12]}
                                    >
                                        <Field
                                            label={t("city")}
                                            inputStyles={inputStyles}
                                            labelStyles={labelStyles}
                                            errorStyles={errorStyles}
                                            name="work_info.located_at.city"
                                            defaultValue={
                                                (values.work_info &&
                                                    values.work_info
                                                        .located_at &&
                                                    values.work_info.located_at
                                                        .city) ||
                                                ""
                                            }
                                            onChange={handleChange}
                                            error={!!errors.city}
                                        />
                                    </Box>
                                    <Box
                                        width={[1, 1, 0.5, 0.5]}
                                        mt={3}
                                        pr={[0, 0, 12, 12]}
                                    >
                                        <InputLabel>{t("state")}</InputLabel>
                                        <MultiSelect
                                            menuShouldScrollIntoView
                                            options={states}
                                            multiSelectValue={
                                                values.work_info &&
                                                values.work_info.located_at &&
                                                values.work_info.located_at
                                                    .state &&
                                                states.find(
                                                    option =>
                                                        option.shortValue ===
                                                        values.work_info
                                                            .located_at.state
                                                )
                                            }
                                            name="work_info.located_at.state"
                                            onChange={(name, option) => {
                                                setFieldValue(
                                                    name,
                                                    option.shortValue
                                                );
                                            }}
                                            error={!!errors.state}
                                            isMulti={false}
                                            isClearable
                                            menuPosition="relative"
                                        />
                                    </Box>
                                    <Box
                                        width={[1, 1, 0.5, 0.5]}
                                        mt={3}
                                        pl={[0, 0, 12, 12]}
                                    >
                                        <Field
                                            label={t("zip")}
                                            inputStyles={inputStyles}
                                            labelStyles={labelStyles}
                                            errorStyles={errorStyles}
                                            name="work_info.located_at.zip_code"
                                            defaultValue={
                                                (values.work_info &&
                                                    values.work_info
                                                        .located_at &&
                                                    values.work_info.located_at
                                                        .zip_code) ||
                                                ""
                                            }
                                            onChange={handleChange}
                                            error={!!errors.zipcode}
                                        />
                                    </Box>
                                    <Box
                                        width={[1, 1, 0.5, 0.5]}
                                        mt={3}
                                        pr={[0, 0, 12, 12]}
                                    >
                                        <InputLabel>
                                            {t("profession")}
                                        </InputLabel>
                                        <MultiSelect
                                            menuShouldScrollIntoView
                                            options={professionOptions}
                                            multiSelectValue={
                                                values.work_info &&
                                                values.work_info.professions &&
                                                professionOptions.find(
                                                    option =>
                                                        option.key ===
                                                        values.work_info
                                                            .professions[0]
                                                )
                                            }
                                            onChange={(name, option) => {
                                                setFieldValue(name, option.key);
                                            }}
                                            isMulti={false}
                                            name="work_info.professions[0]"
                                            isClearable
                                            error={!!errors.profession}
                                            menuPosition="relative"
                                        />
                                    </Box>
                                    <Box
                                        width={[1, 1, 0.5, 0.5]}
                                        mt={3}
                                        pl={[0, 0, 12, 12]}
                                    >
                                        <InputLabel>
                                            {t("profession2")}
                                        </InputLabel>
                                        <MultiSelect
                                            menuShouldScrollIntoView
                                            options={professionOptions}
                                            multiSelectValue={
                                                values.work_info &&
                                                values.work_info.professions &&
                                                professionOptions.find(
                                                    option =>
                                                        option.key ===
                                                        values.work_info
                                                            .professions[1]
                                                )
                                            }
                                            isMulti={false}
                                            name="work_info.professions[1]"
                                            onChange={(name, option) => {
                                                setFieldValue(name, option.key);
                                            }}
                                            isClearable
                                            error={!!errors.profession}
                                            menuPosition="relative"
                                        />
                                    </Box>
                                    <Box
                                        width={[1, 1, 0.5, 0.5]}
                                        mt={3}
                                        pr={[0, 0, 12, 12]}
                                    >
                                        <Field
                                            label={t("fax")}
                                            inputStyles={inputStyles}
                                            labelStyles={labelStyles}
                                            errorStyles={errorStyles}
                                            name="work_info.fax"
                                            defaultValue={
                                                (values.work_info &&
                                                    values.work_info.fax) ||
                                                ""
                                            }
                                            onChange={handleChange}
                                            error={!!errors.fax}
                                        />
                                    </Box>
                                    <Box
                                        width={[1, 1, 0.5, 0.5]}
                                        mt={3}
                                        mb={3}
                                        pl={[0, 0, 12, 12]}
                                    >
                                        <Field
                                            label={t("website")}
                                            inputStyles={inputStyles}
                                            labelStyles={labelStyles}
                                            errorStyles={errorStyles}
                                            name="work_info.website"
                                            defaultValue={
                                                (values.work_info &&
                                                    values.work_info.website) ||
                                                ""
                                            }
                                            onChange={handleChange}
                                            error={!!errors.website}
                                        />
                                    </Box>
                                </Flex>
                            </CollapsableSection>
                            {filtersCollapsed["workInfo"] && (
                                <Box
                                    mt={3}
                                    style={{ borderTop: "1px solid #C4C4C4" }}
                                />
                            )}
                            {/* //SOCIAL NETWORKS INFO SECTION */}
                            <CollapsableSection
                                flexJustify="start"
                                title={t("socialNetworks")}
                                onClickCollapse={collapsed =>
                                    onToggleCollapse(
                                        "socialNetworks",
                                        collapsed
                                    )
                                }
                                collapsed={filtersCollapsed["socialNetworks"]}
                                rootStyles={{ marginTop: "1em" }}
                                titleStyles={titleStyles}
                                arrowDimensions={{ height: 9, width: 15 }}
                                arrowColor={theme.coolBlack}
                            >
                                <Flex flexWrap="wrap">
                                    <Box
                                        width={[1, 1, 0.5, 0.5]}
                                        mt={3}
                                        pr={[0, 0, 12, 12]}
                                    >
                                        <Field
                                            label="Facebook"
                                            inputStyles={inputStyles}
                                            labelStyles={labelStyles}
                                            errorStyles={errorStyles}
                                            name="social_networks.facebook"
                                            defaultValue={
                                                (values.social_networks &&
                                                    values.social_networks
                                                        .facebook) ||
                                                ""
                                            }
                                            onChange={handleChange}
                                            error={!!errors.facebook}
                                        />
                                    </Box>
                                    <Box
                                        width={[1, 1, 0.5, 0.5]}
                                        mt={3}
                                        pl={[0, 0, 12, 12]}
                                    >
                                        <Field
                                            label="Instagram"
                                            inputStyles={inputStyles}
                                            labelStyles={labelStyles}
                                            errorStyles={errorStyles}
                                            name="social_networks.instagram"
                                            defaultValue={
                                                (values.social_networks &&
                                                    values.social_networks
                                                        .instagram) ||
                                                ""
                                            }
                                            onChange={handleChange}
                                            error={!!errors.instagram}
                                        />
                                    </Box>
                                </Flex>
                                <Flex flexWrap="wrap">
                                    <Box
                                        width={[1, 1, 0.5, 0.5]}
                                        mt={3}
                                        pr={[0, 0, 12, 12]}
                                    >
                                        <Field
                                            label="LinkedIn"
                                            inputStyles={inputStyles}
                                            labelStyles={labelStyles}
                                            errorStyles={errorStyles}
                                            name="social_networks.linkedin"
                                            defaultValue={
                                                (values.social_networks &&
                                                    values.social_networks
                                                        .linkedin) ||
                                                ""
                                            }
                                            onChange={handleChange}
                                            error={!!errors.linkedin}
                                        />
                                    </Box>
                                    <Box
                                        width={[1, 1, 0.5, 0.5]}
                                        mt={3}
                                        pl={[0, 0, 12, 12]}
                                    >
                                        <Field
                                            label="Twitter"
                                            inputStyles={inputStyles}
                                            labelStyles={labelStyles}
                                            errorStyles={errorStyles}
                                            name="social_networks.twitter"
                                            defaultValue={
                                                (values.social_networks &&
                                                    values.social_networks
                                                        .twitter) ||
                                                ""
                                            }
                                            onChange={handleChange}
                                            error={!!errors.twitter}
                                        />
                                    </Box>
                                </Flex>
                                <Box
                                    width={[1, 1, 0.5, 0.5]}
                                    mt={3}
                                    pr={[0, 0, 12, 12]}
                                >
                                    <Field
                                        inputStyles={inputStyles}
                                        labelStyles={labelStyles}
                                        errorStyles={errorStyles}
                                        name="social_networks.youtube"
                                        label={t("youtube")}
                                        defaultValue={
                                            (values.social_networks &&
                                                values.social_networks
                                                    .youtube) ||
                                            ""
                                        }
                                        onChange={handleChange}
                                        error={!!errors.youtube}
                                    />
                                </Box>
                            </CollapsableSection>
                            {filtersCollapsed["socialNetworks"] && (
                                <Box
                                    mt={3}
                                    style={{ borderTop: "1px solid #C4C4C4" }}
                                />
                            )}
                            {/* //OTHER INFO SECTION */}
                            <CollapsableSection
                                flexJustify="start"
                                title={t("otherInfo")}
                                onClickCollapse={collapsed =>
                                    onToggleCollapse("otherInfo", collapsed)
                                }
                                collapsed={filtersCollapsed["otherInfo"]}
                                rootStyles={{ marginTop: "1em" }}
                                titleStyles={titleStyles}
                                arrowDimensions={{ height: 9, width: 15 }}
                                arrowColor={theme.coolBlack}
                            >
                                <Flex flexWrap="wrap">
                                    <Box
                                        width={[1, 1, 0.5, 0.5]}
                                        mt={3}
                                        pr={[0, 0, 12, 12]}
                                    >
                                        <InputLabel>{t("birthday")}</InputLabel>
                                        <DateInput
                                            customInput={Field}
                                            onChange={(value, name) => {
                                                setFieldValue(name, value);
                                            }}
                                            dateFormat="MM/dd/yyyy"
                                            fontSize={"16px"}
                                            name="additional_info.birthday"
                                            maxDate={new Date()}
                                            inputStyles={inputStyles}
                                            labelStyles={labelStyles}
                                            errorStyles={errorStyles}
                                            error={!!errors.birthday}
                                            defaultValue={
                                                (values.additional_info &&
                                                    values.additional_info
                                                        .birthday) ||
                                                ""
                                            }
                                        />
                                    </Box>
                                    <Box width={[1, 1, 0.5, 0.5]} mt={3}>
                                        {/* home mobile_phone */}
                                        <InputLabel>
                                            {t("homePhone")}
                                        </InputLabel>
                                        <PhoneNumberInput
                                            useCustomStyle={{
                                                marginRight: 0,
                                                border: "1px solid #DDE3E8"
                                            }}
                                            name="additional_info.phone_home"
                                            value={
                                                (values.additional_info &&
                                                    values.additional_info
                                                        .phone_home) ||
                                                ""
                                            }
                                            onChange={ev =>
                                                setFieldValue(ev.name, ev.value)
                                            }
                                        />
                                    </Box>
                                </Flex>
                            </CollapsableSection>
                            <Flex
                                py="40px"
                                alignItems="center"
                                justifyContent={
                                    isEdit ? "center" : "space-between"
                                }
                                flexDirection={
                                    theme.mobileDevice ? "column" : "row"
                                }
                            >
                                {!contactData.first_name && (
                                    <Box mb={theme.mobileDevice ? "40px" : ""}>
                                        <Text
                                            style={{
                                                textAlign: "left",
                                                fontSize: "14px"
                                            }}
                                            color={theme.taupeGray}
                                        >
                                            <a
                                                href="https://support.nuop.com/portal/en/kb/articles/inviting-referral-partners-best-practices"
                                                style={{ color: theme.newBlue }}
                                            >
                                                {t("clickHere")}
                                            </a>
                                            {t("importMultipleContact")}
                                        </Text>
                                    </Box>
                                )}
                                <Box>
                                    <SquareButton
                                        width={isEdit ? "160px" : "110px"}
                                        text={t(
                                            isEdit ? "saveChanges" : "create"
                                        )}
                                        onClick={handleSubmit}
                                        disabled={!isValid}
                                        isLoading={isLoading}
                                        bg={theme.shamRockGreen}
                                    />
                                </Box>
                            </Flex>
                        </Box>
                    </React.Fragment>
                );
            }}
        </Formik>
    );
});

const filtersCollapsedInitialState = {
    basicInfo: false,
    workInfo: true,
    socialNetworks: true,
    otherInfo: true
}

const CreateContactModal = props => {
    const {
        theme,
        contactData = {},
        createContact,
        updateContact,
        open,
        onClose,
        professionOptions,
        states,
        onContactCreated,
        openErrorModal,
        errorMessage,
        hideCreateContactErrorModal,
        subtitle
    } = props;

    const [filtersCollapsed, setFiltersCollapsed] = useState(filtersCollapsedInitialState);

    useEffect(() => {
        if (!open) {
            setFiltersCollapsed(filtersCollapsedInitialState);
        }
    }, [open]);

    const renderForm = () => open && (
        <FormFields
            subtitle={subtitle}
            filtersCollapsed={filtersCollapsed}
            contactData={contactData}
            createContact={createContact}
            updateContact={updateContact}
            states={states}
            setFiltersCollapsed={setFiltersCollapsed}
            onClose={onClose}
            professionOptions={professionOptions}
            onContactCreated={onContactCreated}
        />
    );

    return (
        <>
            {theme.mobileDevice ? (
                <MobileWrapper open={open}>{renderForm()}</MobileWrapper>
            ) : (
                <PopupModal
                    width={886}
                    open={open}
                    handleClose={onClose}
                    modalContentStyles={{ borderRadius: 8 }}
                >
                    <FormContainer>{renderForm()}</FormContainer>
                </PopupModal>
            )}
            <ErrorPopup
                open={openErrorModal}
                message={errorMessage}
                hide={hideCreateContactErrorModal}
            />
        </>
    );
};

CreateContactModal.propTypes = {
    // Component props
    /**
     * Whether the modal is open or not
     */
    open: PropTypes.bool.isRequired,
    /**
     * Function to close the modal
    */
    onClose: PropTypes.func.isRequired,
    /**
     * Function to call when a contact is created
     * @param {Object} contact The created contact
     * @returns {void}
     * @example
     * onContactCreated = (contact) => {
     *    console.log(contact);
     * }
    */
    onContactCreated: PropTypes.func,
    /**
     * The contact data to edit
     * @example
     * {
     *   first_name: "John",
     *  last_name: "Doe",
     * email: "
     *
     * }
     */
    contactData: PropTypes.object,
    /**
     * The subtitle of the modal. Optional
     * @example
     * "Create a new contact"
     */
    subtitle: PropTypes.string,
    // Redux props
    createContact: PropTypes.func.isRequired,
    updateContact: PropTypes.func.isRequired,
    professionOptions: PropTypes.array.isRequired,
    states: PropTypes.array.isRequired,
    openErrorModal: PropTypes.bool.isRequired,
    errorMessage: PropTypes.string.isRequired,
    hideCreateContactErrorModal: PropTypes.func.isRequired,
};

export default withProps(CreateContactModal);
